<template>
    <div>
        <div class="collapsible_wrapper left" :class="{reveal: stateFilters}">
            <div class="collapsible_container">
                <div class="collapsible_heading">
                    <h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M1 0l9 15.094v5.906l4 3v-8.906l9-15.094h-22zm18.479 2l-2.981 5h-8.996l-2.981-5h14.958z"/></svg>Filtros avanzados
                    </h2>
                    <div class="close_btn right" @click="closeFilters"></div>
                </div>
                <div class="collapsible_block">
                    <h3 class="filter_list_heading">Artistas</h3>
                    <div class="filter_list">
                        <div class="filter_list_item" 
                            v-for="(artista, index) in artistasFiltros" 
                            :key="artista.id">
                            <label @click="changeFilters(1, index)"
                                :class="{selected: filtros.artistas.some(x => x.id == artista.id)}">
                            {{artista.name}}</label>
                        </div>
                    </div>
                </div>
                <div class="collapsible_block">
                    <h3 class="filter_list_heading">Categorías</h3>
                    <div class="filter_list">
                        <div class="filter_list_item" 
                            v-for="(categoria, index) in categoriasFiltros" 
                            :key="categoria.id"
                        >
                            <label @click="changeFilters(2, index)"
                                :class="{selected: filtros.categorias.some(x => x.id == categoria.id)}">
                            {{categoria.name}}</label>
                        </div>
                    </div>
                </div>
                <div class="collapsible_block">
                    <div class="filter_btn mb20" @click="closeFilters">Aplicar filtros</div>
                    <div class="filter_btn" @click="resetFiltros">Limpiar filtros</div>
                </div>
            </div>
        </div>
        <div v-if="filterOpacity > 0" @click="closeFilters" class="translucid_bg" :style="{opacity: filterOpacity}"></div>
        <section class="productos">
            <div class="inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">Productos</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">Productos</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div class="content_block padded rounded white">
                    <div class="products_filters_bar grid flex_space">
                        <div class="grid_col">
                            <div class="products_filters_nav grid_col_box">
                                <div class="products_filters_trigger btn graphic small black" @click="openFilters">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M1 0l9 15.094v5.906l4 3v-8.906l9-15.094h-22zm18.479 2l-2.981 5h-8.996l-2.981-5h14.958z"/></svg>filtros avanzados
                                </div>
                                <div @click="deleteFilter(1, index)" class="products_filters_btn btn graphic_right small black product_filter_artist" v-for="(filter, index) in filtros.artistas" :key="index">
                                    {{filter.name}}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"/></svg>
                                </div>
                                <div @click="deleteFilter(2, index)" class="products_filters_btn btn graphic_right small black" v-for="(filter, index) in filtros.categorias" :key="index">
                                    {{filter.name}}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"/></svg>
                                </div>
                            </div>
                        </div>
                        <div class="grid_col">
                            <div class="products_order grid_col_box">
                                <VitySelect 
                                    :defecto="true"
                                    frase="Ordenar Por"
                                    :opciones="[
                                        {name: 'Destacados', value: 'id.desc'},
                                        {name: 'Nombre A > Z', value: 'name.asc'},
                                        {name: 'Nombre Z > A', value: 'name.desc'},
                                        {name: 'Precio + a -', value: 'price.desc'},
                                        {name: 'Precio - a +', value: 'price.asc'},
                                    ]"
                                    v-model="orden"
                                />
                            </div>
                        </div>
                    </div>
                    <!--<div class="product_list grid__motor grid__motor_col_5">-->
                    <transition-group name="list" tag="div" class="product_list grid__motor grid__motor_col_5">
                        <article class="product_item" v-for="(product, index) in productos" :key="index">
                            <Producto :product="product"/>
                        </article>
                    </transition-group>
                    <!--</div>-->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import Producto from '@/components/Producto'
import VitySelect from '@/components/VitySelect'
import VityCheck from '@/components/VityCheck'

export default {
    name: 'Productos',
    components:{
        VitySelect,
        VityCheck,
        Producto
    },
    data: function(){
        return{
            stateFilters: false,
            filterOpacity: 0,
            filtros: {
                categorias:[],
                artistas: []
            },
            artistasFiltros: [],
            categoriasFiltros: [],
            productos: [],
            orden: ''
        }
    },
    methods: {
        openFilters: function(){
            if(!this.stateFilters){
                this.getArtistas();
                this.getCategorias();
                this.stateFilters = true;
            }
        },
        closeFilters: function(save=0){
            if(this.stateFilters){
                this.stateFilters = false;
            }
        },
        resetFiltros: function(){
            this.filtros = {
                categorias:[],
                artistas: []
            };
            this.getArtistas();
            this.getCategorias();
            this.getProducts();
        },
        getArtistas: function(include = 0){
            let cate = "";
            this.filtros.categorias.forEach(function(x){
                //cate['category[]'] = x;
                if(cate == "")
                    cate += `category[]=${x.id}`;
                else
                    cate += `&category[]=${x.id}`;
            });
            axios
                .get(process.env.VUE_APP_URL+'artists?'+cate)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.artistasFiltros = [];
                        this.artistasFiltros = rs.data;
                        if(include){
                            let index;
                            if((index = this.artistasFiltros.findIndex(x => x.id == include)) < 0)
                                this.$route.push('/productos');
                            this.changeFilters(1, index);
                        }
                    }
                })
                .catch(error => {console.log(error); alert('mal')});
        },
        getCategorias: function(include = 0){
            let arti = "";
            this.filtros.artistas.forEach(function(x){
                //arti['artist[]'] = x;
                if(arti == "")
                    arti += `artist[]=${x.id}`;
                else
                    arti += `&artist[]=${x.id}`;
            });
            axios
                .get(process.env.VUE_APP_URL+'categories?'+arti)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.categoriasFiltros = [];
                        this.categoriasFiltros = rs.data;
                        if(include){
                            let index;
                            if((index = this.categoriasFiltros.findIndex(x => x.id == include)) < 0)
                                this.$route.push('/productos');
                            this.changeFilters(2, index);
                        }
                    }
                })
                .catch(error => {console.log(error); alert('mal')});
        },
        deleteFilter: function(type, id){
            if(type == 1){
                this.filtros.artistas.splice(id, 1);
            }
            else if(type == 2){
                this.filtros.categorias.splice(id, 1);
            }
            this.getProducts();
        },
        changeFilters: function(type, id){
            var index;
            if(type == 1){
                if((index = this.filtros.artistas.findIndex(x => x.id == this.artistasFiltros[id].id)) >= 0)
                    this.filtros.artistas.splice(index, 1);
                else
                    this.filtros.artistas.push(this.artistasFiltros[id]);
                this.getCategorias();
            }
            else if(type == 2){
                if(index = this.filtros.categorias.findIndex(x => x.id == this.categoriasFiltros[id].id) >= 0)
                    this.filtros.categorias.splice(index, 1);
                else
                    this.filtros.categorias.push(this.categoriasFiltros[id]);
                this.getArtistas();
            }
            this.getProducts();
        },
        getProducts: function(scroll=0){
            let params = "";
            this.filtros.artistas.forEach(function(x){
                //arti['artist[]'] = x;
                if(params == "")
                    params += `artist[]=${x.id}`;
                else
                    params += `&artist[]=${x.id}`;
            });
            this.filtros.categorias.forEach(function(x){
                //cate['category[]'] = x;
                if(params == "")
                    params += `category[]=${x.id}`;
                else
                    params += `&category[]=${x.id}`;
            });
            let order = this.orden ? this.orden.split('.') : ['id', 'desc'];
            axios
                .get(process.env.VUE_APP_URL+'products?'+params, {
                    params:{
                        'orderBy[column]': order[0],
                        'orderBy[order]': order[1],
                        limit: 15,
                        offset: scroll ? this.productos.length : null,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.productos = rs.data;
                    }
                })
                .catch(error => {console.log(error);alert('mal')})
        }
    },
    watch:{
        stateFilters: function() {
            if(this.stateFilters){
                //document.documentElement.style.overflow = 'hidden';
                var time = 0;
                var intval = setInterval(() => {
                    if(time >= 700)
                        clearInterval(intval);
                    this.filterOpacity = time/700;
                    time += 10;
                }, 1);
                return;
            }

            //document.documentElement.style.overflow = 'auto';
            var time = 700;
            var intval = setInterval(() => {
                if(time <= 0)
                    clearInterval(intval);
                this.filterOpacity = time/700;
                time -= 10;
            }, 1);
        },
        orden: function(){
            this.getProducts();
        }
    },
    created: function(){
        if(this.$route.query.category){
            if(this.categoriasFiltros.length <= 0)
                this.getCategorias(this.$route.query.category);
        }
        if(this.$route.query.artist){
            if(this.artistasFiltros.length <= 0)
                this.getArtistas(this.$route.query.artist);
        }
        if(this.$route.query.q){
            //this.getArtistas(this.$route.query.artist);
        }
        this.getProducts();
    },
    beforeRouteUpdate (to, from, next) {
		if(to.query.category){
            this.filtros.categorias.push(to.query.category);
        }
        if(to.query.artist){
            this.filtros.artistas.push(to.query.artist);
        }
        if(to.query.q){
            //this.getArtistas(this.$route.query.artist);
        }
        this.getProducts();
        next();
	}
}
</script>
